<template>
    <div>
        <agile ref="carousel" @after-change="resetLoader" :autoplay="isEnabled" :navButtons="false" :autoplaySpeed="(time * 1000)" timing="ease-in-out" :speed="600" :pauseOnHover="false">
            <slot></slot>
        </agile>

        <div class="absolute right-8 top-1/3 lg:top-1/2 grid gap-2" v-if="isEnabled">
            <div class="relative w-9 h-9">
                <div class="absolute cursor-pointer text-white bg-gray p-2 rounded-full bg-opacity-60" @click="goToNext">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
                <div ref="loader">
                    <div class="loader animate" :style="{'animation-duration': '10s'}"></div>
                </div>
            </div>

            <div class="relative w-9 h-9">
                <div class="absolute cursor-pointer text-white bg-gray p-2 rounded-full bg-opacity-60" @click="goToPrev">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    name: 'Slider',
    components: {
        agile: VueAgile
    },
    props: {
        enabled: {
            default: false
        },
        time: {
            default: 5,
            type: Number
        },
    },
    computed: {
        isEnabled() {
            return this.enabled === 'enable'
        }
    },
    methods: {
        resetLoader() {
            this.$refs.loader.innerHTML = `<div class="loader animate" style="animation-duration: ${this.time}s"></div>`
        },
        goToNext() {
            this.$refs.carousel.goToNext()
            this.$refs.carousel.restartAutoPlay()
        },
        goToPrev() {
            this.$refs.carousel.goToPrev()
            this.$refs.carousel.restartAutoPlay()
        }
    }
}
</script>
