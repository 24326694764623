import axios from 'axios'
import EventBus from '../EventBus'

window.addToCart = (productID, packageID = null) => {
    axios.post('/kosik', { productID, packageID }).then((response) => {

        const { count, dataLayer } = response.data

        EventBus.$emit('added', response.data)

        document.getElementById('cart').innerText = count
        document.getElementById('cart-mobile').innerText = count

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push(dataLayer)

    }).catch((error) => {
        console.log(error)
    })
}
