<template>
    <div :class="toggleClass" class="z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="close"></div>

            <div class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:m-8 sm:align-middle sm:max-w-4xl">
                <div id="inline-rent" class="bg-white p-6 flex flex-col overflow-y-auto">
                    <i class="absolute top-4 right-4 fas fa-times-circle text-red cursor-pointer" @click="close"></i>

                    <h2 class="font-black mb-6 text-2xl text-center">Žiadosť o nezáväznú cenovú ponuku prenájmu {{title}}</h2>

                    <h3 class="font-bold text-xl">Údaje zákazníka</h3>

                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                        <div>
                            <div class="relative z-0 w-full mb-5">
                                <input type="text" id="company_name" v-model="form.company" name="company" placeholder=" " class="py-2 px-4 block w-full mt-0 bg-transparent border-2 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" :class="{ 'border-red': errors.includes('company')}" />
                                <label class="absolute -top-2.5 left-2.5 text-black bg-white px-2 text-sm required">Firma</label>
                            </div>
                        </div>
                        <div>
                            <div class="relative z-0 w-full mb-5">
                                <input type="text" id="firstname" v-model="form.name" name="name" placeholder=" " class="py-2 px-4 block w-full mt-0 bg-transparent border-2 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" :class="{ 'border-red': errors.includes('name')}" />
                                <label class="absolute -top-2.5 left-2.5 text-black bg-white px-2 text-sm required">Meno</label>
                            </div>
                        </div>
                        <div>
                            <div class="relative z-0 w-full mb-5">
                                <input type="text" id="lastname" v-model="form.lastname" name="lastname" placeholder=" " class="py-2 px-4 block w-full mt-0 bg-transparent border-2 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" :class="{ 'border-red': errors.includes('lastname')}" />
                                <label class="absolute -top-2.5 left-2.5 text-black bg-white px-2 text-sm required">Priezvisko</label>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                        <div>
                            <div class="relative z-0 w-full mb-5">
                                <input type="text" id="company_registration_number" v-model="form.company_id" name="company_id" placeholder=" " class="py-2 px-4 block w-full mt-0 bg-transparent border-2 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" :class="{ 'border-red': errors.includes('company_id')}" />
                                <label class="absolute -top-2.5 left-2.5 text-black bg-white px-2 text-sm required">IČO</label>
                            </div>
                        </div>
                        <div>
                            <div class="relative z-0 w-full mb-5">
                                <input type="text" id="phone" v-model="form.phone" name="phone" placeholder=" " class="py-2 px-4 block w-full mt-0 bg-transparent border-2 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" :class="{ 'border-red': errors.includes('phone')}" />
                                <label class="absolute -top-2.5 left-2.5 text-black bg-white px-2 text-sm required">Mobilný telefón</label>
                            </div>
                        </div>
                        <div>
                            <div class="relative z-0 w-full mb-5">
                                <input type="text" id="email" v-model="form.email" name="email" placeholder=" " class="py-2 px-4 block w-full mt-0 bg-transparent border-2 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" :class="{ 'border-red': errors.includes('email')}" />
                                <label class="absolute -top-2.5 left-2.5 text-black bg-white px-2 text-sm required">Email</label>
                            </div>
                        </div>
                    </div>

                    <h3 class="font-bold text-xl">Rozpis prenajímaných položiek</h3>

                    <div class="grid grid-cols-4 gap-4 py-2">
                        <div class="col-span-3">
                            <h3 class="font-bold mb-2">{{ title }}</h3>
                            <ul class="text-sm list-disc list-inside text-gray" v-if="accessory">
                                <li v-for="item in accessory">
                                    {{ item.nazov }}
                                </li>

                                <li v-if="takeAway">
                                    Odvoz starého zariadenia technikom
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-1">
                            <div class="text-right font-bold">{{ price }} € s DPH / mesiac</div>
                        </div>
                    </div>

                    <div class="grid grid-cols-1">
                        <div class="grid grid-cols-4 gap-4 py-2" v-if="blackWhitePrice">
                            <div class="col-span-3">
                                <h3 class="font-bold">Cena za čiernobiely výtlačok</h3>
                            </div>
                            <div class="col-span-1">
                                <div class="text-right font-bold">{{ blackWhitePrice }} € s DPH / výtlačok</div>
                            </div>
                        </div>

                        <div class="grid grid-cols-4 gap-4 py-2" v-if="colorPrice">
                            <div class="col-span-3">
                                <h3 class="font-bold">Cena za farebný výtlačok</h3>
                            </div>
                            <div class="col-span-1">
                                <div class="text-right font-bold">{{ colorPrice }} € s DPH / výtlačok</div>
                            </div>
                        </div>

                        <div class="grid grid-cols-4 gap-4 py-2" v-if="scanPrice">
                            <div class="col-span-3">
                                <h3 class="font-bold">Cena za sken</h3>
                            </div>
                            <div class="col-span-1">
                                <div class="text-right font-bold">{{ scanPrice }} € s DPH / sken</div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <button class="bg-green rounded-full mt-2 py-2 px-4 text-sm font-bold text-white hover:bg-green-dark transition" @click="send" v-if="!posted && !loading">
                            Odoslať požiadavku na nezáväznú cenovú ponuku
                        </button>

                        <button class="bg-gray rounded-full mt-2 py-2 px-4 text-sm font-bold text-white" v-if="!posted && loading">
                            <i class="fas fa-spinner fa-spin"></i>
                        </button>

                        <button id="rent-successful" class="bg-green rounded-full mt-2 py-2 px-4 text-sm font-bold text-white" v-if="posted">
                            Požiadavka bola odoslaná
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../EventBus'
import axios from "axios";

export default {
    name: 'Rent',
    props: ['blackWhitePrice', 'colorPrice', 'scanPrice'],
    data() {
        return {
            show: false,
            loading: false,
            posted: false,
            errors: [],
            title: '',
            skId: null,
            description: '',
            price: '',
            accessory: false,
            takeAway: false,
            form: {
                company: '',
                name: '',
                lastname: '',
                company_id: '',
                phone: '',
                email: '',
                token: null,
            }
        }
    },
    mounted() {
        EventBus.$on('show', ({title, skId, description, price, accessory, prices, takeAway}) => {
            this.title = title
            this.skId = skId
            this.description = description
            this.price = price
            this.accessory = accessory
            this.takeAway = takeAway
            if (prices) {
                this.blackWhitePrice = prices[0] ?? 0
                this.colorPrice = prices[1] ?? 0
                this.scanPrice = prices[2] ?? 0
            }
            this.show = true
            this.errors = []
            this.loading = false
            this.posted = false
            this.form = {
                company: '',
                name: '',
                lastname: '',
                company_id: '',
                phone: '',
                email: '',
                test: false,
                token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        });
    },
    computed: {
        toggleClass() {
            return this.show ? 'fixed' : 'hidden'
        }
    },
    methods: {
        close() {
            this.title = ''
            this.skId = null
            this.description = ''
            this.price = ''
            this.accessory = false
            this.takeAway = false
            this.show = false
            this.errors = []
            this.loading = false
            this.posted = false
            this.form = {
                company: '',
                name: '',
                lastname: '',
                company_id: '',
                phone: '',
                email: '',
                test: false,
                token: null,
                termsAndRights: false
            }
        },
        async send() {
            this.loading = true
            this.errors = []

            if (this.form.company === '') {
                this.errors.push('company')
            }

            if (this.form.company_id === '') {
                this.errors.push('company_id')
            }

            if (this.form.name === '') {
                this.errors.push('name')
            }

            if (this.form.lastname === '') {
                this.errors.push('lastname')
            }

            if (this.form.email === '') {
                this.errors.push('email')
            }

            if (this.form.phone === '') {
                this.errors.push('phone')
            }

            // if (!this.form.termsAndRights) {
            //     this.errors.push('termsAndRights')
            // }

            if (this.errors.length > 0) {
                this.loading = false
                return true
            }

            setTimeout(async () => {
                const response = await axios.post('/odoslat-poziadavku', {
                    _token: this.token,
                    title: this.title,
                    skId: this.skId,
                    price: this.price,
                    accessory: this.accessory,
                    form: this.form,
                    prices: {
                        blackWhite: this.blackWhitePrice,
                        color: this.colorPrice,
                        scan: this.scanPrice
                    }
                })

                if (+response.status === 200) {
                    this.loading = false
                    this.posted = true

                    setTimeout(() => {
                        this.close()
                    }, 4000)
                }
            }, 300)
        }
    }
}
</script>

<style scoped lang="sass">
    #inline-rent
        max-height: calc(100vh - 4rem)
</style>
