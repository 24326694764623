<template>
    <div>
        <div class="grid grid-cols-1 gap-2 mt-8 text-center lg:rounded" :class="grid">
            <div class="cursor-pointer py-4 hover:bg-black hover:text-white transition rounded md:rounded-none"
                 :class="[{'md:rounded-l': index === 0}, {'md:rounded-r': index === (tabs.length - 1)}, {'bg-gray-light': active !== tab.id}, {'bg-black text-white': active === tab.id}]"
                 v-for="(tab, index) in tabs"
                 @click="activate(tab.id)">
                {{ tab.title }}
            </div>
        </div>

        <slot :active="active" :showFeatures="showFeatures" :toggle="toggle"></slot>
    </div>
</template>

<script>
const getActiveTab = () => {
    const queryString = window.location.search

    if(queryString) {
        const urlParams = new URLSearchParams(queryString)
        const tabValue = urlParams.get('tab')

        if(tabValue) {
            return tabValue
        }
    }

    return null
}

export default {
    name: 'Tabs',
    props: ['tabs'],
    data() {
        return {
            active: getActiveTab() ?? this.tabs[0].id,
            showFeatures: false,
        }
    },
    computed: {
        grid() {
            return `md:grid-cols-${this.tabs.length} md:gap-0`;
        }
    },
    methods: {
        toggle() {
            if(this.showFeatures) {
                const scrollDiv = document.getElementById("tabs-wrapper").offsetTop - 50;
                window.scrollTo({ top: scrollDiv, behavior: 'smooth'});

                setTimeout(() => {
                    this.showFeatures = false
                }, 500)
            } else {
                this.showFeatures = true
            }
        },
        activate(id) {
            this.active = id

            window.history.replaceState(null, null, `?tab=${id}`)
        }
    }
}
</script>
