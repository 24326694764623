<template>
    <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1" v-if="errors.includes(label)" :class="classes">
        <slot></slot>
    </span>
</template>

<script>
export default {
    name: 'CartError',
    props: {
        label: {
            type: String,
            default: ''
        },
        errors: {
            type: Array,
            default: []
        },
        mb: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'mb-4': this.mb
            }
        }
    }
}
</script>
