import Vue from 'vue'
import Tabs from './components/Tabs'
import Cart from './components/Cart'
import CartPayments from './components/CartPayments'
import CartError from './components/CartError'
import Rent from './components/Rent'
import ContactForm from './components/ContactForm'
import Autocomplete from './components/Autocomplete'
import Slider from './components/Slider'
import Glightbox from 'glightbox'
import axios from 'axios'
import vSelect from 'vue-select'

window.axios = axios
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}

import './web/cart'
import EventBus from './EventBus'

Vue.directive("click-outside", {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
});

new Vue({
    el: '#app',
    components: {
        Tabs,
        Cart,
        Rent,
        ContactForm,
        CartPayments,
        CartError,
        Autocomplete,
        Slider,
        vSelect
    },
    data: {
        filter: {
            first: 0,
            second: 0,
            third: 0,
            firstValues: {},
            secondValues: {},
            thirdValues: {},
            breadcrumbs: {},
        },
        cart: {
            form: {
                companyName: '',
                registrationNumber: '',
                taxNumber: '',
                taxIdentifier: '',
                isPersonal: false,
                firstName: '',
                lastName: '',
                address: '',
                city: '',
                postalCode: '',
                email: '',
                phone: '',
                isDifferentDelivery: false,
                deliveryFirstName: '',
                deliveryLastName: '',
                deliveryAddress: '',
                deliveryCity: '',
                deliveryPostalCode: '',
                deliveryEmail: '',
                deliveryPhone: '',
                deliveryId: '',
                paymentId: '',
                termsAndRights: false,
                heureka: false
            },
            voucher: {
                code: null,
                result: null,
                message: null
            },
            payments: [],
            errors: [],
            loading: false,
            created: false,
        }
    },
    computed: {
        models() {
            if(this.filter.thirdValues.length > 0) {
                return [{
                    'code': 0,
                    'label': 'Vyberte model'
                }, ...this.filter.thirdValues.map(value => {
                    return {
                        'code': value.id,
                        'label': value.title,
                    }
                })]
            }

            return [{
                'code': 0,
                'label': 'Vyberte model'
            }];
        }
    },
    mounted() {
        if (document.querySelector('[data-delivery-free]')) {
            this.changeDelivery(+document.querySelector('[data-delivery-free]').dataset.deliveryId)
        }

        if (document.querySelectorAll('.delivery').length === 1 && !document.querySelector('[data-delivery-free]')) {
            this.changeDelivery(+document.querySelector('.delivery').dataset.deliveryId)
        }

        if (!document.getElementById('select-filter')) {
            return false
        }

        const breadcrumbs = JSON.parse(document.getElementById('select-filter').dataset.breadcrumbs) || {};
        this.filter.breadcrumbs = breadcrumbs ? Object.entries(breadcrumbs).map(item => {
            return item[1].id;
        }) : {};

        const firstValues = JSON.parse(document.getElementById('select-filter').dataset.firstValues) || {};
        this.filter.firstValues = firstValues;

        const found = this.filter.breadcrumbs.filter(value => Object.entries(firstValues).map(item => {
            return item[1].id;
        }).includes(value));

        if (found) {
            this.filter.first = +found;
        }
    },
    methods: {
        handleFilter() {
            let slug = null;

            if (this.filter.second) {
                slug = this.filter.secondValues.find(value => {
                    return (value.id === this.filter.second)
                }).slug
            }

            if (this.filter.third) {
                slug = this.filter.thirdValues.find(value => {
                    return (value.id === this.filter.third)
                }).slug
            }

            if (slug) {
                location.href = `/kategoria/${slug}`;
            }
        },
        async applyVoucher() {
            this.cart.voucher.result = null
            this.cart.voucher.message = null

            const response = await axios.post('/kosik/voucher', {
                code: this.cart.voucher.code
            })

            if(+response.status === 200) {
                this.cart.voucher.result = response.data.result
                this.cart.voucher.message = response.data.message

                if(this.cart.voucher.result) {
                    this.cart.voucher.code = null

                    location.reload()
                }
            }
        },
        async removeVoucher(code) {
            await axios.post('/kosik/vymazat-voucher', {
                code: code
            })

            location.reload()
        },
        async changeDelivery(deliveryID) {
            this.cart.form.deliveryId = deliveryID
            this.cart.form.paymentId = ''

            const response = await axios.post('/kosik/platby', {deliveryID})

            this.cart.errors = this.cart.errors.filter(error => {
                return error !== 'deliveryId'
            })

            if (+response.status === 200) {
                this.cart.payments = response.data.payments

                if (response.data.payments.length === 1) {
                    await this.changePayment(response.data.payments[0].id)
                }

                await this.getDeliveryAndPaymentPrice()
            }
        },
        async changePayment(paymentID) {
            this.cart.form.paymentId = paymentID

            this.cart.errors = this.cart.errors.filter(error => {
                return error !== 'paymentId'
            })

            await this.getDeliveryAndPaymentPrice()
        },
        async getDeliveryAndPaymentPrice() {
            const deliveryID = this.cart.form.deliveryId
            const paymentID = this.cart.form.paymentId

            const response = await axios.post('/kosik/sumar', {deliveryID, paymentID})

            if (+response.status === 200) {
                const {deliveryPrice, totalPrice} = response.data

                document.getElementById('delivery-total-price').innerText = deliveryPrice
                document.getElementById('cart-total-price').innerText = totalPrice
            }
        },
        validateOrder() {
            this.cart.errors = []

            const form = this.cart.form
            const company = ['companyName', 'registrationNumber', 'taxNumber']
            const customer = ['firstName', 'lastName', 'address', 'city', 'postalCode', 'email', 'phone', 'deliveryId', 'paymentId', 'termsAndRights']
            const delivery = ['deliveryFirstName', 'deliveryLastName', 'deliveryAddress', 'deliveryCity', 'deliveryPostalCode', 'deliveryEmail', 'deliveryPhone']

            company.forEach(input => {
                if (!form.isPersonal && !form[input]) {
                    this.cart.errors.push(input)
                }
            })

            customer.forEach(input => {
                if (!form[input]) {
                    this.cart.errors.push(input)
                }
            })

            delivery.forEach(input => {
                if (form.isDifferentDelivery && !form[input]) {
                    this.cart.errors.push(input)
                }
            })
        },
        async order() {
            this.cart.loading = true

            this.validateOrder()

            if (this.cart.errors.length > 0) {
                this.cart.loading = false

                return false
            }

            const response = await axios.post('/api/createOrder', this.cart.form)

            if (response.data.error) {
                this.cart.errors.push('email')
                this.cart.loading = false

                return false
            }

            if (+response.status === 200 && response.data.orderId) {
                window.location = '/kosik/dokoncenie'
            }
        }
    },
    watch: {
        async 'filter.first'() {
            if (this.filter.first === 0) {
                this.filter.second = 0
                this.filter.third = 0
                return true
            }

            try {
                const values = await axios.get(`/api/subCollections/${this.filter.first}`)

                this.filter.second = 0
                this.filter.third = 0

                this.filter.secondValues = values.data.map(value => {
                    return {
                        'id': value.id,
                        'title': value.title,
                        'slug': value.slug
                    }
                })

                const found = this.filter.breadcrumbs.filter(value => Object.entries(this.filter.secondValues).map(item => {
                    return item[1].id;
                }).includes(value));

                if (found) {
                    this.filter.second = +found;
                }

                this.filter.thirdValues = {}
            } catch (e) {
                console.log(e)
            }
        },
        async 'filter.second'() {
            if (this.filter.second === 0) {
                this.filter.third = 0
                return true
            }

            try {
                const values = await axios.get(`/api/subCollections/${this.filter.second}`)

                this.filter.third = 0

                this.filter.thirdValues = values.data.map(value => {
                    return {
                        'id': value.id,
                        'title': value.title,
                        'slug': value.slug
                    }
                })

                const found = this.filter.breadcrumbs.filter(value => Object.entries(this.filter.thirdValues).map(item => {
                    return item[1].id;
                }).includes(value));

                if (found) {
                    this.filter.third = +found;
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
})

Glightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true
})

window.getFilters = (force = false) => {
    const button = document.getElementById('filter-button');
    const filters = document.querySelectorAll("input[type=checkbox][name=filter]:checked")

    if (window.getComputedStyle(button).display !== 'none' && !force) {
        return true
    }

    const list = Array.from(filters).map(el => {
        return {
            type: el.dataset.type,
            value: el.dataset.value
        };
    }).reduce((acc, currentValue) => {
        if (!acc[currentValue.type]) {
            acc[currentValue.type] = [];
        }

        acc[currentValue.type].push(currentValue.value);
        return acc;
    }, {});

    const response = Object.keys(list).map((el, index) => {
        return `${index}:${list[el].join(',')}`
    })

    if ('URLSearchParams' in window) {
        const searchParams = new URLSearchParams(window.location.search);
        if (response.length > 0) {
            searchParams.set('filters', response.join('-'));
        } else {
            searchParams.delete('filters');
        }

        searchParams.delete('page');

        window.location.search = decodeURIComponent(searchParams.toString());
    }
}

window.removeFilter = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('filters');
    window.location.search = searchParams.toString();
}

window.showRent = (title, skId, description, price, accessory = false, prices = false) => {
    EventBus.$emit('show', {
        title, skId, description, price, accessory, prices
    });
}

window.toggleFilter = () => {
    const el = document.getElementById('filter-wrapper')
    const isHidden = el.classList.contains('invisible')

    if (isHidden) {
        el.classList.remove('invisible')
        el.classList.remove('h-0')
    } else {
        el.classList.add('invisible')
        el.classList.add('h-0')
    }
}

window.toggleMobileMenu = (isOpen) => {
    const el = document.getElementById('mobile-menu')

    if (isOpen) {
        el.classList.add('active')
    } else {
        el.classList.remove('active')
    }
}

window.showInformationForm = () => {
    window.chatButton.onClick();
}

window.onscroll = () => {
    const el = document.getElementById('mobile-menu')

    if (!el.classList.contains('active')) {
        el.classList.toggle('has-background', el.scrollTop > 200 || document.documentElement.scrollTop > 200)
    }
}
