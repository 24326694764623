<template>
    <div :class="toggleClass" class="z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="close"></div>

            <div class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                <div class="bg-white">
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 bg-gray-lighter">
                        <div class="col-span-1 md:col-span-2 p-4 sm:p-6">
                            <h2 class="font-black mb-6">PRODUKT BOL VLOŽENÝ DO VÁŠHO KOŠÍKA</h2>

                            <div class="grid grid-cols-3 gap-8">
                                <div class="col-span-1">
                                    <img :src="item.photo" class="object-contain h-75 w-full">
                                </div>

                                <div class="col-span-2 flex flex-col justify-center">
                                    <h2 class="font-bold text-base my-1">{{ item.title || '' }}</h2>
                                    <p class="text-sm my-1 text-gray">{{ item.desc || '' }}</p>
                                    <p class="text-sm my-1 text-red font-bold">Cena: {{ item.price || '0,00 €' }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-1 p-4 sm:p-6 flex flex-col justify-between">
                            <h2 class="font-black mb-6">NÁKUPNÝ KOŠÍK</h2>

                            <div>
                                <p class="text-sm mb-3">Celkový počet produktov: {{ count }}</p>
                                <p class="text-sm">Spolu za produkty {{ price }}</p>
                            </div>

                            <div class="mt-6">
                                <div class="grid grid-cols-1 gap-4">
                                    <button @click="close" class="col-span-1 flex justify-center items-center bg-black rounded-full py-2 px-4 text-sm text-center font-bold text-white hover:bg-gray transition">
                                        Pokračovať v nákupe
                                    </button>

                                    <a href="/kosik" class="col-span-1 flex justify-center items-center bg-red rounded-full py-2 px-4 text-sm text-center font-bold text-white hover:bg-red-dark transition">
                                        Prejsť do košíka
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-4 sm:p-6" v-if="item.products && item.products.length > 0">
                        <h2 class="font-black mb-6 text-2xl">Odporúčame zakúpiť</h2>

                        <div class="grid grid-cols-2 lg:grid-cols-5 gap-5">
                            <div class="flex flex-col items-center text-center relative" v-for="product in item.products">
                                <span class="absolute bg-black rounded-full py-1 px-2 text-white text-xs font-bold right-0" v-if="product.is_alternative">Alternatíva</span>

                                <a :href="`/produkt/${product.slug}`" class="hover:underline">
                                    <img :src="product.gallery[0] ? `https://www.cbcslovakia.sk/storage/${product.gallery[0].path.replace('{size}', 'sm')}` : '/images/example-product.jpg'" class="object-contain h-20 w-full" :alt="product.title">
                                </a>

                                <h3 class="font-black mt-2 h-20 flex items-center text-sm">
                                    <a :href="`/produkt/${product.slug}`" class="hover:underline">{{ product.title }}</a>
                                </h3>

                                <p class="text-gray text-xs leading-6 mt-2 flex flex-col items-center flex-grow">
                                    {{ product.perex }}
                                </p>

                                <p class="font-black mt-2 flex items-center text-sm text-red">
                                    {{ product.price }} € s DPH
                                </p>

                                <a :href="`/produkt/${product.slug}`" class="w-full bg-black rounded-full mt-4 py-2 px-4 text-xs font-bold text-white hover:bg-gray transition cursor-pointer" v-if="product.is_buyable">
                                    Kúpiť
                                </a>

                                <a :href="`/produkt/${product.slug}`" class="w-full bg-gray-200 rounded-full mt-4 py-2 px-4 text-xs font-bold text-black hover:bg-gray-300 transition" v-else>
                                    Informovať sa o cene
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../EventBus'

export default {
    name: 'Cart',
    data() {
        return {
            show: false,
            count: 0,
            price: 0,
            item: {},
        }
    },
    mounted() {
        EventBus.$on('added', ({count, price, item}) => {
            this.show = true
            this.count = count
            this.price = price
            this.item = item

            console.log(item)
        });
    },
    computed: {
        toggleClass() {
            return this.show ? 'fixed' : 'hidden'
        }
    },
    methods: {
        close() {
            this.show = false
            this.count = 0
            this.price = 0
            this.item = {}
        }
    }
}
</script>
