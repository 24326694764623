<template>
    <div>
        <div v-for="payment in payments" v-if="payments.length > 0">
            <div :id="`payment-@{payment.id}`" @click="changePayment(payment.id)" class="flex mb-4 bg-white border rounded flex-col cursor-pointer" :class="{'active-payment': +payment.id === +selected, pulse: pulse}">
                <div class="flex flex-col my-4 px-6 justify-between font-semibold text-gray-darker">
                    <h4 class="text-sm uppercase">{{ payment.external_title }} (+ {{ payment.formatted_price }})</h4>
                    <div class="text-xs text-gray delivery-description" v-html="payment.description"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CartPayments',
    props: ['payments', 'selected', 'setPayment', 'pulse'],
    methods: {
        changePayment(paymentId) {
            this.setPayment(paymentId)
        }
    }
}
</script>
